

































































import {
  BryntumButton,
  BryntumWidget,
  BryntumTextField,
} from '@bryntum/schedulerpro-vue'

import { Popup, TextAreaField } from '@bryntum/schedulerpro'
import {
  PAGE_ORIENTATION_LANDSCAPE,
  PAGE_ORIENTATION_PORTRAIT,
} from '@/components/bryntum/configs/SchedulerExportConfig'

export default {
  name: 'SchedulerToolbarExport',

  components: {
    BryntumButton,
    BryntumWidget,
    BryntumTextField,
  },

  props: {
    pageOrientation: { type: String, default: undefined },
    hideEmptyLocations: { type: Boolean, default: false },
    comment: { type: String, default: undefined },
    scale: { type: Number, default: undefined },
    minScale: { type: Number, default: undefined },
    maxScale: { type: Number, default: undefined },
    workingTimeRange: { type: Array, default: undefined },
    minWorkingTime: { type: Number, default: undefined },
    maxWorkingTime: { type: Number, default: undefined },
    fileName: { type: String, default: undefined },
    isSaving: { type: Boolean, default: false },
  },

  emits: [
    'update:pageOrientation',
    'update:comment',
    'update:scale',
    'update:workingTimeRange',
    'update:fileName',
    'printFile',
    'exit',
  ],

  computed: {
    mdAndDown(): boolean {
      return this.$vuetify.breakpoint.mdAndDown
    },

    pageOrientationCfg(): Record<string, string> {
      return {
        text: this.mdAndDown ? '' : 'Rotate page',
        icon: this.pageOrientation === PAGE_ORIENTATION_LANDSCAPE ? 'mdi mdi-phone-rotate-portrait' : 'mdi mdi-phone-rotate-landscape',
        tooltip: this.pageOrientation === PAGE_ORIENTATION_LANDSCAPE ? 'Switch to portrait orientation' : 'Switch to landscape orientation',
      }
    },

    hideEmptyLocationsCfg(): Record<string, string> {
      return {
        text: this.mdAndDown ? '' : (this.hideEmptyLocations ? 'Show all locations' : 'Hide empty locations'),
        icon: this.hideEmptyLocations ? 'mdi mdi-eye-remove-outline' : 'mdi mdi-eye-outline',
        tooltip: 'Hides/shows locations that have no sessions. Note, it considers all sessions in the time span regardless working time. If the location stays visible, but there are no sessions on the screen, try to adjust working time range to see the data.'
      }
    },

    workingTimeHint(): string {
      const [ start, end ] = this.workingTimeRange

      if (start === this.minWorkingTime && end === this.maxWorkingTime) {
        return 'Working time: all day'
      }

      return `Working time: ${start}:00 - ${end}:00`
    },
  },

  watch: {
    isSaving(newVal: boolean, oldVal: boolean): void {
      if (newVal !== oldVal) {
        this.onSavingStatusChange(newVal)
      }
    },
  },

  methods: {
    onPageOrientationClick(): void {
      this.$emit(
        'update:pageOrientation',
        this.pageOrientation === PAGE_ORIENTATION_LANDSCAPE ? PAGE_ORIENTATION_PORTRAIT : PAGE_ORIENTATION_LANDSCAPE
      )
    },

    onHideEmptyLocationsClick(): void {
      this.$emit('update:hideEmptyLocations', !this.hideEmptyLocations)
    },

    onAddCommentClick(): void {
      const popup = new Popup({
        header: 'Enter comment',
        autoShow: true,
        centered: true,
        modal: true,
        closeAction: 'destroy',
        closable: true,
        width: '40em',
        items: {
          commentField: {
            type: 'textarea',
            name: 'comment',
            value: this.comment,
            fieldLabel: 'Comment',
            labelWidth: '100%',
            labelAlign: 'left',
            labelPosition: 'top',
            maxLength: 3000,
            height: '20em',
          }
        },
        bbar: [
          {
            text: 'Cancel',
            minWidth: 100,
            onAction: () => {
              popup.close()
            }
          },
          {
            text: 'Save',
            minWidth: 100,
            onAction: () => {
              const { commentField } = popup.widgetMap as { commentField: TextAreaField }
              this.$emit('update:comment', commentField.value)
              popup.close()
            }
          }
        ],
      })
    },

    onScaleChange(value: number): void {
      this.$emit('update:scale', value)
    },

    onWorkingTimeRangeChange(value: number[]): void {
      const [ start, end ] = value

      const updatedValue = start !== end ? value : this.workingTimeRange

      this.$emit('update:workingTimeRange', updatedValue)
    },

    onSavingStatusChange(isSaving: boolean): void {
      const saveButton = this.$refs.saveButton.instance

      if (isSaving) {
        saveButton.mask(this.mdAndDown ? '' : 'Printing')
      } else {
        saveButton.unmask()
      }
    },
  },
}
