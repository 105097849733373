











import { BryntumGrid } from '@bryntum/schedulerpro-vue'
import { PublishSessionsGridConfig } from '@/components/bryntum/configs/PublishSessionsGridConfig'

export default {
  name: 'PublishSessionsGrid',

  components: {
    BryntumGrid,
  },

  props: {
    rawData: { type: Array, default: undefined },
    height: { type: Number, default: 400 },
  },

  emits: [
    'cancel',
    'publish',
  ],

  data() {
    return {
      PublishSessionsGridConfig,
      gridInstance: null,
    }
  },

  mounted(): void {
    this.gridInstance = this.$refs.PublishSessionsGrid.instance

    this.gridInstance.selectAll()

    this.gridInstance.on('cancel', this.onCancelClick)
    this.gridInstance.on('publish', this.onPublishClick)
  },

  beforeDestroy(): void {
    this.gridInstance.un('cancel')
    this.gridInstance.un('publish')
  },

  methods: {
    onCancelClick(): void {
      this.$emit('cancel')
    },

    onPublishClick(): void {
      this.$emit('publish', this.gridInstance.selectedRecords)
    },
  }
}
