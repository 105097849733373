<template>
  <div class="calendar-dashboard fill-height">
    <!-- Use v-show to prevent destroy/mount cycle when export mode is on and off -->
    <div
      v-show="!isExport"
      class="primary-container"
    >
      <scheduler-toolbar
        :start-date="startDate"
        :end-date="endDate"
        :view-preset="viewPreset"
        :theme-id="selectedTheme"
        :fit-columns="fitColumns"
        :column-width="columnWidth"
        :tick-height="tickHeight"
        :filters="filters"
        :read-only-view="readOnlyView"
        @update:dateRange="updateDateRange"
        @update:viewPreset="updateViewPreset"
        @update:themeId="updateSelectedTheme"
        @update:fillWidth="updateFillWidth"
        @update:fitWidth="updateFitWidth"
        @update:columnWidth="updateColumnWidth"
        @update:tickHeight="updateTickHeight"
        @update:filterStatus="updateFilterStatus"
        @pdfExportStart="() => { isExport = true }"
        @publishAllSessions="publishAllSessionsInTimeSpan"
      />
      <div class="calendar-dashboard-container">
        <bryntum-scheduler-pro
          ref="verticalScheduler"
          v-bind="SchedulerConfig"
          cls="vertical-scheduler"
          :resources="studios"
          :view-preset="viewPreset"
          :read-only="readOnlyView"
        />
        <sidebar-container
          v-if="!readOnlyView"
          :squads="squads"
          :selected-squad="selectedSquad"
          :groups="participants.playerGroups"
          :players="participants.allPlayers"
          :templates="rawEventTemplates"
          @update:selectedSquad="updateSelectedSquad"
          @groupGridMounted="groupGridMounted"
          @groupGridUnmounted="groupGridUnmounted"
          @playerGridMounted="playerGridMounted"
          @playerGridUnmounted="playerGridUnmounted"
          @sessionTemplateGridMounted="sessionTemplateGridMounted"
          @sessionTemplateGridUnmounted="sessionTemplateGridUnmounted"
        />
      </div>
    </div>
    <!-- Use v-if to mount/destroy component when export mode is on and off -->
    <calendar-export
      v-if="isExport"
      :start-date="startDate"
      :end-date="endDate"
      :project="scheduler.project"
      :squad="selectedSquad"
      :original-tick-size="tickHeight"
      @pdfExportEnd="() => { isExport = false }"
    />
    <!-- Use v-if to destroy the grid once the dialog is closed -->
    <v-dialog
      v-if="showPublishingDialog"
      v-model="showPublishingDialog"
      width="600"
    >
      <publish-sessions-grid
        :raw-data="sessionsToPublish"
        @cancel="closePublishingDialog"
        @publish="publishSelectedSessions"
      />
      <v-overlay :value="isPublishing">
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="10"
          :value="publishedSessionsPercentage"
          color="primary"
        >
          {{ publishedSessions }} / {{ totalSessionsToPublish }}
        </v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script lang="js">

import { SchedulerConfig } from '@/components/bryntum/configs/SchedulerConfig'
import { ZOOM_LEVEL_CUSTOM, ZOOM_LEVELS } from '@/components/bryntum/configs/ZoomLevelsConfig'
import SidebarContainer from '@/components/SidebarContainer.vue'
import PublishSessionsGrid from '@/components/PublishSessionsGrid.vue'
import CalendarExport from '@/components/export/CalendarExport.vue'
import SchedulerToolbar from '@/components/SchedulerToolbar'
import GroupDrag from '@/components/bryntum/drag/GroupDrag.js'
import PlayerDrag from '@/components/bryntum/drag/PlayerDrag.js'
import SessionTemplateDrag from '@/components/bryntum/drag/SessionTemplateDrag.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-vue'
import { DateHelper, MessageDialog, ObjectHelper } from '@bryntum/schedulerpro'
import loadPlayers from '@/api/Player'
import loadStudios from '@/api/Studio'
import loadSurfaces from '@/api/Surface'
import loadEventTemplates from '@/api/EventTemplate'
import { eventBus } from '@/helpers/EventBus'
import AssignmentValidatorPopup from '@/components/bryntum/widgets/AssignmentValidatorPopup'
import EventTypeModel from '@/components/bryntum/models/EventTypeModel'
import SessionTemplateModel from '@/components/bryntum/models/SessionTemplateModel'
import BenchmarkDateHelper from '@/components/bryntum/helper/BenchmarkDateHelper'
import OverrideHelper from '@/components/bryntum/helper/OverrideHelper'
import { getSelectedTheme, setSelectedTheme } from '@/components/bryntum/configs/ThemeSwitcherConfig'
import SessionElementStore from '@/components/bryntum/stores/SessionElementStore'
import ElementWorkloadModel from '@/components/bryntum/models/ElementWorkloadModel'
import {
  CONFIRM_DELETE_PUBLISHED_SESSION,
  CONFIRM_DELETE_SESSION,
  CONFIRM_UPDATE_PUBLISHED_SESSION,
} from '@/components/bryntum/configs/MessageDialogConfirmationConfig'
import {
  COLUMN_WIDTH_MAX,
  COLUMN_WIDTH_MIN,
  FIT_MENU_ITEM_NONE,
  FIT_MENU_ITEM_FILL,
  FIT_MENU_ITEM_FIT,
} from '@/components/bryntum/configs/ToolbarConfigs'
import { TICK_HEIGHT_MAX, TICK_HEIGHT_MIN } from '@/components/bryntum/configs/ToolbarConfigs'

const WEEK_START_DAY = SchedulerConfig.weekStartDay
const DEFAULT_ZOOM_LEVEL_ID = SchedulerConfig.viewPreset
const FIRST_ITEM = 0
const SECOND_ITEM = 1

export default {
  name: 'CalendarDashboard',

  components: {
    SchedulerToolbar,
    BryntumSchedulerPro,
    PublishSessionsGrid,
    SidebarContainer,
    CalendarExport,
  },

  beforeRouteEnter(to, from, next) {
    const { view = '', start = '', x = '', y = '' } = to.query

    const zoomLevelId = `benchmark-${view}`
    const isViewQueryValid = Boolean(ZOOM_LEVELS[zoomLevelId])
    const initZoomLevelId = isViewQueryValid ? zoomLevelId : DEFAULT_ZOOM_LEVEL_ID
    const { shiftUnit, shiftIncrement, tickWidth, tickHeight } = ZOOM_LEVELS[initZoomLevelId]
    SchedulerConfig.viewPreset = initZoomLevelId

    const isDateQueryValid = BenchmarkDateHelper.isValidDateFormat(start)
    const initDate = isDateQueryValid ? start : BenchmarkDateHelper.format(new Date(), 'YYYY-MM-DD')
    const parsedDate = BenchmarkDateHelper.parse(initDate, 'YYYY-MM-DD')
    const initStartDate = BenchmarkDateHelper.startOf(parsedDate, shiftUnit, true, WEEK_START_DAY)
    const initEndDate = BenchmarkDateHelper.add(initStartDate, shiftIncrement, shiftUnit)

    const parsedX = parseInt(x)
    const isValidColumnWidth = !isNaN(parsedX) && parsedX >= COLUMN_WIDTH_MIN && parsedX <= COLUMN_WIDTH_MAX
    const initColumnWidth = isValidColumnWidth ? parsedX : tickWidth
    SchedulerConfig.resourceColumns.columnWidth = initColumnWidth

    const parsedY = parseInt(y)
    const isValidTickHeight = !isNaN(parsedY) && parsedY >= TICK_HEIGHT_MIN && parsedY <= TICK_HEIGHT_MAX
    const initTickHeight = isValidTickHeight ? parsedY : tickHeight
    SchedulerConfig.tickSize = initTickHeight

    const query = {
      view: initZoomLevelId.split('-')[SECOND_ITEM],
      start: initDate,
      x: `${initColumnWidth}`,
      y: `${initTickHeight}`,
    }

    const isQueryValid = isViewQueryValid && isDateQueryValid && isValidColumnWidth && isValidTickHeight

    next(vm => {
      vm.startDate = initStartDate
      vm.endDate = initEndDate
      vm.viewPreset = initZoomLevelId
      vm.columnWidth = initColumnWidth
      vm.tickHeight = initTickHeight

      if (!isQueryValid) {
        vm.$router.replace({
          ...to,
          query,
        })
      }
    })
  },

  data: () => ({
    showPublishingDialog: false,
    sessionsToPublish: [],
    isPublishing: false,
    publishedSessions: 0,
    totalSessionsToPublish: 0,
    readOnlyView: false,
    SchedulerConfig,
    // Bryntum scheduler instance
    scheduler: null,
    groupGrid: null,
    playerGrid: null,
    sessionTemplateGrid: null,
    groupGridDragHelper: null,
    playerGridDragHelper: null,
    sessionTemplateGridDragHelper: null,
    startDate: null,
    endDate: null,
    viewPreset: DEFAULT_ZOOM_LEVEL_ID,
    selectedTheme: getSelectedTheme(),
    fitColumns: FIT_MENU_ITEM_NONE,
    columnWidth: 100,
    tickHeight: 100,
    studios: [],
    participants: {
      allPlayers: [],
      playerGroups: []
    },
    surfaces: [],
    rawEventTemplates: [],
    isExport: false,
    filters: {
      name: '',
      status: 'all',
    },
    showEditor: false,
    events: [],
    currentEvent: {},
    selectedSquad: null,
    // Copied sessions might be pasted into a different day, time, and location. (on schedule click)
    sessionPerTimeClipboard: null,
    // Copied sessions might be pasted into a different day, and location. Time is preserved. (on resource header click)
    sessionsPerLocationClipboard: null,
    // Copied sessions might be pasted into a different day. Time and location are preserved. (on timeline click)
    sessionsPerDayClipboard: null,
  }),

  computed: {
    ...mapGetters({
      user: 'Auth/user',
      loading: 'Index/loading',
      getSquads: 'Event/getSquads',
      getSurfaces: 'Surface/getSurfaces',
      getPlayerGroups: 'PlayerGroup/getPlayerGroups',
      getEventTypes: 'EventType/getEventTypes',
      getEventTemplates: 'EventTemplate/getList'
    }),

    squads() {
      return this.getSquads
    },

    groups() {
      return this.getPlayerGroups
    },

    eventTypes() {
      return this.getEventTypes
    },

    eventTemplates() {
      return this.getEventTemplates
    },

    filterStatusValue() {
      return this.readOnlyView ? 'published' : this.filters.status
    },

    publishedSessionsPercentage() {
      return this.totalSessionsToPublish ? Math.floor(this.publishedSessions / this.totalSessionsToPublish * 100) : 0
    }
  },
  watch: {
    selectedSquad: {
      handler: async function (squadId) {
        eventBus.$emit('reloadGrid')
      }
    },

    startDate(value) {
      const start = BenchmarkDateHelper.format(value, 'YYYY-MM-DD')

      if (this.$route.query.start !== start) {
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            start,
          },
        })
      }
    },

    viewPreset(value) {
      const view = value.split('-')[SECOND_ITEM]

      if (this.$route.query.view !== view) {
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            view,
          },
        })
      }
    },

    columnWidth(value) {
      const x = `${value}`

      if (this.$route.query.x !== x) {
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            x,
          },
        })
      }
    },

    tickHeight(value) {
      const y = `${value}`

      if (this.$route.query.y !== y) {
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            y,
          },
        })
      }
    },

    'filters.name'() {
      this.filterByName()
    },

    'filters.status'() {
      this.filterByStatus()
    },
  },

  beforeMount() {
    const userRoles = this.user.roles || []

    // TODO: it's better to reverse the logic - default `readOnlyView` to `true` and set to `false` for specific roles
    if (userRoles.includes('client_view_only')) {
      this.readOnlyView = true
    }

    this.setLoading(true)
  },

  async mounted() {
    // Setting theme requires initial HTML to be rendered
    setSelectedTheme(this.selectedTheme)

    // Fetch Squads first!
    await this.fetchSquads()
    await this.fetchEventTypes()

    if (this.squads[FIRST_ITEM]) {
      this.updateSelectedSquad(this.squads[FIRST_ITEM])
    }

    // selectedSquad should be set before we call for data
    await loadSurfaces(this)
    await loadStudios(this)
    await loadPlayers(this)
    await loadEventTemplates(this)

    const scheduler = this.scheduler = this.$refs.verticalScheduler.instance

    OverrideHelper.overrideVerticalDateRange(scheduler)

    scheduler.setTimeSpan(this.startDate, this.endDate)

    await this.fetchAndLoadEvents()

    // region Init toolbar items
    this.initFitColumns()
    // No need to init here since it's been init in beforeRouteEnter
    // this.initColumnWidth()
    // this.initTickHeight()
    // endregion

    // Init Drag and Drops
    this.initDragAndDropForGroupGrid()
    this.initDragAndDropForPlayerGrid()
    this.initDragAndDropForSessionTemplateGrid()

    this.$nextTick(() => {
      /**
       * https://www.bryntum.com/docs/scheduler-pro/api/Core/data/Store#events
       */
      scheduler.eventStore.on({
        update: this.onEventStoreUpdate,
        remove: this.onSessionDelete,
        addConfirmed: this.onSessionAdded,
        add: this.onSessionAdd,
        thisObj: scheduler
      })

      scheduler.on({
        // region Task editing
        beforeTaskEdit: this.onBeforeTaskEdit,
        beforeTaskEditShow: this.onBeforeTaskEditorShow,
        beforeTaskSave: this.onBeforeTaskEditorSave,
        afterTaskSave: this.onAfterTaskEditorSave,
        afterTaskEdit: this.onAfterTaskEdit,
        taskEditCanceled: this.onAfterTaskEditCanceled,
        // endregion
        // region Copy-pasting
        scheduleMenuBeforeShow: this.onScheduleMenuBeforeShow,
        copySessionPerTime: this.onCopySessionPerTime,
        pasteSessionPerTime: this.onPasteSessionPerTime,
        // endregion
        // region Publishing
        publishSession: this.publishSessionFromEditor,
        unpublishSession: this.unpublishSessionFromEditor,
        resetToLastPublishedSession: this.resetToLastPublishedSession,
        // endregion
        // region Filtering
        updateNameFilter: this.updateNameFilter,
        // endregion
        beforeEventDropFinalize: this.onBeforeEventDropFinalize,
        sessionTemplateDrop: this.onSessionTemplateDrop,
        playerDrop: this.onPlayerDrop,
        groupDrop: this.onGroupDrop,
        dateRangeChange: this.fetchAndLoadEvents,
        beforeDestroy: this.onSchedulerBeforeDestroy
      })
    })

    this.setLoading(false)

    eventBus.$on('reloadGrid', async () => {
      if (this.loading) {
        return
      }
      await loadStudios(this)
      await loadPlayers(this)
      scheduler.eventStore.suspendEvents(false)
      await this.fetchAndLoadEvents()
      scheduler.eventStore.resumeEvents()
    })

    return true
  },

  methods: {
    ...mapMutations({
      setLoading: 'Index/SET_LOADING',
      showError: 'Index/SET_SNACKBAR_ERROR',
      showInfo: 'Index/SET_SNACKBAR_INFO',
    }),
    ...mapActions({
      deleteEvent: 'Event/DELETE',
      fetchEvent: 'Event/VIEW',
      createEvent: 'Event/STORE',
      updateEvent: 'Event/UPDATE',
      fetchStudios: 'Studio/FETCH_LIST',
      fetchEventTemplates: 'EventTemplate/FETCH_LIST',
      fetchPlayers: 'Player/FETCH_LIST',
      fetchBallets: 'Player/FETCH_BALLETS',
      fetchPianists: 'Player/FETCH_PIANISTS',
      fetchDancers: 'Player/FETCH_DANCERS',
      fetchPlayerGroups: 'PlayerGroup/FETCH_LIST',
      fetchSurfaces: 'Surface/FETCH_LIST',
      fetchEvents: 'Event/FETCH_LIST',
      fetchSquads: 'Event/FETCH_SQUADS',
      fetchSquadEvent: 'Event/FETCH_SQUAD_EVENT',
      setEditorStatus: 'Event/EDITOR_STATUS',
      fetchEventTypes: 'EventType/FETCH_LIST'
    }),

    onSchedulerBeforeDestroy() {
      eventBus.$off('reloadGrid')
    },

    async onEventStoreUpdate({ record, source: eventStore, changes }) {
      const { isEditing } = this.scheduler.features.taskEdit
      const isNewSession = record.isPhantom
      const isSync = record.isSessionSync

      /**
       * Do not react on update when we create event
       * Do not react on update when we edit event
       * Do not react on update when we sync event data
       */
      if (isNewSession || isEditing || isSync) {
        return
      }

      await this.saveSession({ record })
      eventStore.commit()
    },

    async onBeforeTaskEditorSave({ source: schedule, taskRecord: record, editor }) {
      editor.mask({
        text:'Saving...'
      })
      await this.saveSession({ record })
      schedule.eventStore.commit()
      editor.unmask()
    },

    async onAfterTaskEditorSave({ editor }) {
      const { workloadGrid, elementsGrid } = editor.widgetMap
      // Commit changes, so they cannot be reverted by next cancel
      workloadGrid.store.commit()
      elementsGrid.store.commit()

      // In case we want to fast-sync backend data and frontend records after editing, fetch and load events.
      // await this.fetchAndLoadEvents()
    },

    onAfterTaskEdit({ source: scheduler, taskRecord, editor }) {
      const { tabs, elementsGrid } = editor.widgetMap
      // Reset active tab, so the 1st tab is shown next time
      tabs.activeTab = 0

      elementsGrid.store.destroy()

      /**
       * TODO check with Bryntum examples and report the issue
       * Refresh scheduler manually, because pro scheduler mode='vertical'
       * doesn't remove event from DOM when we cancel event creation
       */
      if (taskRecord.isPhantom) {
        scheduler.refresh()
      }
    },

    onAfterTaskEditCanceled({ editor }) {
      const { workloadGrid, elementsGrid } = editor.widgetMap
      // Revert all changes done to grids
      workloadGrid.store.revertChanges()
      elementsGrid.store.revertChanges()
    },

    onBeforeTaskEdit({ source: scheduler, taskRecord: sessionRecord }) {
      const {
        sessionTemplateField,
        playerGroupsField,
        playersField,
        surfaceField,
        elementsGrid,
        workloadGrid,
      } = scheduler.features.taskEdit.editor.widgetMap

      sessionTemplateField.store.data = [...this.eventTemplates]
      playerGroupsField.store.data = [...this.participants.playerGroups]
      playersField.store.data = [...this.participants.allPlayers]
      surfaceField.store.data = [...this.surfaces]

      const assignedAthletes = sessionRecord.players.filter((rec) => rec.isAthlete)
      // elementsGrid.store.data = [...sessionRecord.sessionElements]
      elementsGrid.store = new SessionElementStore({
        data: [...sessionRecord.sessionElements]
      })

      // When we assign/unassign players, we need to add/remove ElementWorkload records for the player
      elementsGrid.store.on({
        update: () => {
          this.updateElementWorkloadData(sessionRecord, elementsGrid.store)
        }
      })

      const sessionElementPlayersColumn = elementsGrid.columns.get('playerIds')
      const sessionElementPlayersEditor = sessionElementPlayersColumn.editor
      sessionElementPlayersEditor.store.data = [...assignedAthletes]

      workloadGrid.store.data = [...sessionRecord.playerWorkloads]
    },

    onBeforeTaskEditorShow({ editor, taskRecord: sessionRecord }) {
      editor.title = sessionRecord.isPhantom ? 'New Session' : 'Edit Session'

      const { owner: scheduler } = editor

      const {
        publishButton,
        draftButton,
        resetToPublishedButton,
        sessionTemplateField,
        elementsTab,
        tabs,
        workloadGrid,
        bbar,
      } = editor.widgetMap

      // Disable it instead of hide it to prevent unwanted animation in v5.0.5
      // https://2amigoscg.atlassian.net/browse/SCHED-111
      // TODO: try to use hide option after library update
      elementsTab.disabled = sessionRecord.sessionElements.length === 0
      // elementsTab.hidden = sessionRecord.sessionElements.length === 0

      publishButton.hidden = sessionRecord.published
      draftButton.hidden = sessionRecord.draft
      resetToPublishedButton.hidden = sessionRecord.draft

      bbar.hidden = scheduler.readOnly

      // TODO: "disabled" does not work, monitor this issue: https://forum.bryntum.com/viewtopic.php?t=23395
      sessionTemplateField.disabled = !!sessionRecord.sessionTemplate
      sessionTemplateField.readOnly = !!sessionRecord.sessionTemplate

      // Make sure the 1st tab is active
      tabs.activeTab = 0

      // Forward beforeHide to workloadGrid
      editor.on({
        beforeHide: () => {
          workloadGrid.trigger('editorBeforeHide', editor)
        },
        once: true,
      })
    },

    updateElementWorkloadData(sessionRecord, sessionElementStore) {
      const workloads = [...sessionRecord.playerWorkloads]

      workloads.forEach(workloadRecord => {
        const sessionElementRecords = sessionElementStore.elementsByPlayerId(workloadRecord.playerId)
        workloadRecord.updatePlayerElementWorkloads(sessionElementRecords)
      })

      sessionRecord.playerWorkloads = workloads
    },

    onScheduleMenuBeforeShow({ items }) {
      if (this.sessionPerTimeClipboard == null) {
        items.pasteEvent = false
      }
    },

    onCopySessionPerTime({ sessionRecord }) {
      this.sessionPerTimeClipboard = this.mapRecordForData(sessionRecord)
    },

    onPasteSessionPerTime({ startDate, locationRecord }) {
      if (this.sessionPerTimeClipboard == null || !this.scheduler) {
        return
      }

      const { duration, duration_unit } = this.sessionPerTimeClipboard
      const endDate = DateHelper.add(startDate, duration, duration_unit)

      const eventData = this.clearIdentitiesFromSessionData({
        ...this.mapDataForRecord(this.sessionPerTimeClipboard),
        resourceId: locationRecord.id,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })

      const record = this.scheduler.eventStore.createRecord(eventData)
      this.scheduler.eventStore.append(record)
    },

    clearIdentitiesFromSessionData(eventData) {
      // Clear session ID so a new record is created
      delete eventData.id

      // Clear player workload record IDs so new records are created
      const playerWorkloads = eventData.playerWorkloads || eventData.player_workloads
      playerWorkloads.forEach(playerWorkload => {
        delete playerWorkload.id

        // Clear player element workload record IDs so new records are created
        playerWorkload.player_element_workloads.forEach(playerElementWorkload => {
          delete playerElementWorkload.id
        })
      })

      return eventData
    },

    async onSessionTemplateDrop({ schedule, data }) {
      const eventData = this.mapDataForRecord(data)
      const record = schedule.eventStore.createRecord(eventData)
      schedule.eventStore.append(record)
    },

    onSessionAdd({ source: eventStore, records }) {
      records.forEach(async (record) => {
        if (!record.isCreating) {
          // To be sure a new added session will be marked as 'draft' (for the copy action for example)
          record.draft = true
          await this.saveSession({ record })
          eventStore.commit()
        }
      })
    },

    onSessionAdded({ source: eventStore }) {
      eventStore.commit()
    },

    async onPlayerDrop({ schedule, targetSession, player }) {
      await this.processDD(
        { schedule, targetSession, draggedPlayers: [player] }
      )
    },

    async onGroupDrop({ schedule, targetSession, group }) {
      await this.processDD(
        { schedule, targetSession, draggedPlayers: group.players, group }
      )
    },

    async processDD({ schedule, targetSession, draggedPlayers, group }) {
      if (targetSession.published) {
        const result = await MessageDialog.confirm({ ...CONFIRM_UPDATE_PUBLISHED_SESSION })
        // User cancelled update. Do not save changes.
        if (result !== MessageDialog.okButton) {
          return
        }
      }

      const rangeSessions = schedule.eventStore.getEvents({
        startDate: targetSession.startDate,
        endDate: targetSession.endDate
      })

      if (group) {
        schedule.eventStore.suspendEvents(false)
        targetSession.assignGroup(group)
        schedule.eventStore.resumeEvents()
      }

      targetSession.assignPlayers(draggedPlayers)

      if (rangeSessions.length > 1) {
        const validatorConfig = { targetSession, rangeSessions, selectedPlayers: draggedPlayers }
        //TODO Check warning
        const validatorPopup = new AssignmentValidatorPopup(validatorConfig)
        if (validatorPopup.hasIntersections()) {
          await validatorPopup.validate()
        }
      }
    },

    async onBeforeEventDropFinalize({ context }) {
      const { eventRecord: sessionRecord } = context

      if (sessionRecord.published) {
        context.async = true

        const result = await MessageDialog.confirm({ ...CONFIRM_UPDATE_PUBLISHED_SESSION })

        // `true` to accept the changes or `false` to reject them
        context.finalize(result === MessageDialog.yesButton)
      }
    },

    rollbackDeletion() {
      this.scheduler.eventStore.revertChanges()
      this.scheduler.refresh()
    },

    confirmDeletion() {
      this.scheduler.eventStore.commit()
      this.scheduler.refresh()
    },

    async onSessionDelete({ source: eventStore, records }) {
      const sessionRecord = records[FIRST_ITEM]

      if (sessionRecord.isPhantom) {
        return this.confirmDeletion()
      }

      if (sessionRecord.published) {
        const result = await MessageDialog.confirm({ ...CONFIRM_DELETE_PUBLISHED_SESSION })
        // User cancelled deletion. Do not delete session.
        if (result !== MessageDialog.okButton) {
          return this.rollbackDeletion()
        }

        const snapshotRecord = eventStore.getById(sessionRecord.snapshotId)

        if (!snapshotRecord) {
          this.showError(`Cannot find snapshot with ID ${sessionRecord.snapshotId}`)
          return this.rollbackDeletion()
        }

        this.scheduler.mask({
          text:'Deleting the snapshot...'
        })

        try {
          await this.deleteEvent(snapshotRecord.id)
        } catch (error) {
          this.scheduler.unmask()
          return this.rollbackDeletion()
        }

        eventStore.suspendEvents()
        eventStore.remove(snapshotRecord.id)
        eventStore.resumeEvents()

        this.scheduler.unmask()
      } else {
        const result = await MessageDialog.confirm({ ...CONFIRM_DELETE_SESSION })
        // User cancelled deletion. Do not delete session.
        if (result !== MessageDialog.okButton) {
          return this.rollbackDeletion()
        }
      }

      this.scheduler.mask({
        text:'Deleting the session...'
      })

      try {
        await this.deleteEvent(sessionRecord.id)
      } catch (error) {
        this.scheduler.unmask()
        return this.rollbackDeletion()
      }

      this.scheduler.unmask()

      return this.confirmDeletion()
    },

    async fetchAndLoadEvents() {
      // Scheduler is expected to be initialised when this function is called
      const startDate = this.scheduler.startDate
      const endDate = this.scheduler.endDate

      this.scheduler.mask('Fetching events...')

      const events = await this.fetchEventsForDates({ startDate, endDate })

      if (this.scheduler.eventStore) {
        console.info(`${events.length} record(s) are loaded to the store`)

        this.scheduler.suspendRefresh()
        this.scheduler.eventStore.clearFilters()
        await this.scheduler.eventStore.loadDataAsync(events)
        this.initFilters()
        this.scheduler.resumeRefresh(true)
      }

      this.scheduler.unmask()
    },

    async fetchEventsForDates({ startDate, endDate }) {
      // Workaround for https://2amigoscg.atlassian.net/browse/BEN-1650
      // Add offset to start/end dates to grab more events
      const offset = 1 // day
      const filterStartDate = DateHelper.add(startDate, -offset, 'day')
      const filterEndDate = DateHelper.add(endDate, offset, 'day')

      const startDateDay = DateHelper.format(filterStartDate, 'DD-MM-YYYY')
      const endDateDay = DateHelper.format(filterEndDate, 'DD-MM-YYYY')
      const squadId = this.selectedSquad.id

      const sessionFilter = (session) => {
        let keepSessionInDataset = true

        // We check that only the start date is within the scheduler's visual date range
        const sessionStartDate = new Date(session.start_at)
        // sessionStartDate >= startDate and < endDate
        const isWithinRange = DateHelper.betweenLesser(sessionStartDate, startDate, endDate)

        if(!isWithinRange) {
          // While workaround for https://2amigoscg.atlassian.net/browse/BEN-1650
          // is used, it is only an info message.
          console.info(
            '[Date Issue] The session start date is outside of the scheduler date range.\n',
            `${session.id} - Session ID.\n`,
            `${sessionStartDate} - Session start date.\n`,
            `${startDate} - Scheduler start date.\n`,
            `${endDate} - Scheduler end date.\n`,
            session
          )
          keepSessionInDataset = false
        }

        const isLocationFound = Boolean(this.studios.find((studio) => studio.id === session.studio_id))

        if(!isLocationFound) {
          console.warn(
            '[Location Issue] The session is in a location that does not exist.\n',
            `${session.id} - Session ID.\n`,
            `${session.studio_id} - Location ID.\n`,
            session
          )
          keepSessionInDataset = false
        }

        return keepSessionInDataset
      }

      console.info(`%cFetching events for squad ${squadId} from ${startDateDay} to ${endDateDay}`, 'color:green; font-weight: bold')
      const rawSessions = await this.fetchEvents({ startDateDay, endDateDay, squadId })
      console.info(`${rawSessions.length} record(s) are fetched from server`)

      console.groupCollapsed('Events validation')
      const validSessions = rawSessions.filter(sessionFilter)
      console.groupEnd()

      const events = []

      validSessions.forEach((session) => {
        // Add snapshot records to the event store together with regular records
        if (session.snapshot) {
          events.push(this.mapDataForRecord(session.snapshot))
        }
        events.push(this.mapDataForRecord(session))
      })

      return events
    },

    async saveSession({ record: sessionRecord }) {
      const sessionRawObject = this.mapRecordForData(sessionRecord)
      const isNewSession = sessionRecord.isPhantom

      let response

      if (isNewSession) {
        response = await this.createEvent(sessionRawObject)
      } else {
        response = await this.updateEvent(sessionRawObject)
      }

      const savedSessionRawObject = this.mapDataForRecord(response)

      sessionRecord.isSessionSync = true
      await sessionRecord.setAsync({ ...savedSessionRawObject })
      sessionRecord.isSessionSync = false

      return sessionRecord
    },

    // region Session publishing
    publishAllSessionsInTimeSpan() {
      const { eventStore } = this.scheduler

      const visibleSessions = eventStore.getEvents({
        startDate: this.scheduler.timeAxis.startDate,
        endDate: this.scheduler.timeAxis.endDate,
        filter: (session) => session.draft && !session.isSnapshot,
      })

      if (visibleSessions.length === 0) {
        this.showInfo('There are no unpublished sessions in the selected timespan')
        return
      }

      this.sessionsToPublish = visibleSessions
      this.showPublishingDialog = true
    },

    closePublishingDialog() {
      this.showPublishingDialog = false
      this.sessionsToPublish = []
      this.totalSessionsToPublish = 0
      this.publishedSessions = 0
    },

    async publishSelectedSessions(sessions = []) {
      const { eventStore } = this.scheduler

      // Need to make sure published sessions are not in the list
      const unpublishedSessions = sessions.filter(session => session.draft && !session.isSnapshot)

      this.totalSessionsToPublish = unpublishedSessions.length

      let countPublished = 0

      this.isPublishing = true

      // Parallel publishing
      const publishPromises = unpublishedSessions.map(async (session) => {
        const success = await this.publishSession(session)

        if (success) {
          countPublished++
          this.publishedSessions = countPublished
        }

        return { success, session }
      })

      await Promise.allSettled(publishPromises)

      // Small pause to let user see 100% complete
      await new Promise(resolve => setTimeout(resolve, 200))

      this.isPublishing = false

      this.showInfo(`${countPublished} session(s) published`)

      await eventStore.commitAsync()
      this.filterOutSnapshots()
      this.closePublishingDialog()
    },

    async publishSession(sessionRecord) {
      const { eventStore } = this.scheduler
      const sessionRawObject = this.mapRecordForData(sessionRecord)
      const sessionRawData = this.clearIdentitiesFromSessionData({
        ...sessionRawObject,
        is_snapshot: true,
      })

      let response = null

      try {
        response = await this.createEvent(sessionRawData)
      } catch (error) {
        return false
      }

      const savedSessionRawObject = this.mapDataForRecord(response)

      // true is for silence
      eventStore.add(savedSessionRawObject, true)

      await sessionRecord.setAsync({
        published: true,
        snapshotId: savedSessionRawObject.id,
      })

      return true
    },

    async publishSessionFromEditor({ record: sessionRecord, editor }) {
      editor.mask({
        text:'Publishing...'
      })

      const success = await this.publishSession(sessionRecord)

      editor.unmask()

      if (success) {
        editor.onSaveClick()
        this.filterOutSnapshots()
      }
    },

    async unpublishSessionFromEditor({ record: sessionRecord, editor }) {
      const { eventStore } = this.scheduler
      const snapshotRecord = eventStore.getById(sessionRecord.snapshotId)

      if (!snapshotRecord) {
        this.showError(`Cannot find snapshot with ID ${sessionRecord.snapshotId}`)
        return
      }

      editor.mask({
        text: 'Unpublishing...'
      })

      try {
        await this.deleteEvent(snapshotRecord.id)
      } catch (error) {
        editor.unmask()
        return
      }

      eventStore.suspendEvents()
      eventStore.remove(snapshotRecord.id)
      eventStore.resumeEvents()

      this.scheduler.refresh()

      editor.unmask()

      // Reset snapshot info for the session
      await sessionRecord.setAsync({
        draft: true,
        snapshotId: null,
      })

      editor.onSaveClick()

      this.filterOutSnapshots()
    },

    async resetToLastPublishedSession({ record: sessionRecord, editor }) {
      const { eventStore } = this.scheduler
      const snapshotRecord = eventStore.getById(sessionRecord.snapshotId)

      if (!snapshotRecord) {
        this.showError(`Cannot find snapshot with ID ${sessionRecord.snapshotId}`)
        return
      }

      editor.close()

      this.scheduler.mask({
        text:'Resetting to the last published session...'
      })

      // Delete actual session
      await this.deleteEvent(sessionRecord.id)

      eventStore.suspendEvents()
      eventStore.remove(sessionRecord.id)
      eventStore.resumeEvents()

      // Turn snapshot into a standalone session
      await snapshotRecord.setAsync({
        isSnapshot: false,
        readOnly: false,
      })

      await eventStore.commit()

      this.filterOutSnapshots()

      this.scheduler.refresh()

      this.scheduler.unmask()
    },
    // endregion

    // region Session filters
    updateNameFilter({ value }) {
      this.filters.name = value
    },

    updateFilterStatus({ value }) {
      this.filters.status = value
    },

    initFilters() {
      this.filterOutSnapshots()
      this.filterByName()
      this.filterByStatus()
    },

    filterOutSnapshots() {
      this.scheduler.eventStore.filter({
        id: 'sessionNotSnapshot',
        filterBy: (session) => !session.isSnapshot,
      })
    },

    filterByName() {
      const filterId = 'sessionName'
      const filterValue = this.filters.name

      if (filterValue) {
        this.scheduler.eventStore.filter({
          id: filterId,
          filterBy: (session) => session.name.toLowerCase().includes(filterValue.toLowerCase()),
        })
      } else {
        this.scheduler.eventStore.removeFilter(filterId)
      }
    },

    filterByStatus() {
      const filterId = 'sessionStatus'
      const filterValue = this.filterStatusValue

      if (filterValue !== 'all') {
        this.scheduler.eventStore.filter({
          id: filterId,
          filterBy: (session) => session.status === filterValue,
        })
      } else {
        this.scheduler.eventStore.removeFilter(filterId)
      }
    },
    // endregion

    /**
     * TODO move mapRecordForData and mapDataForRecord to SessionModel class
     */
    mapRecordForData(record) {
      const recordsToIds = (records) => {
        return records ? records.map((rec) => rec.id) : []
      }

      const session = {
        id: !record.isPhantom ? record.id : undefined,
        title: record.name,
        duration: record.duration,
        duration_unit: record.durationUnit,
        event_template_id: record.sessionTemplate.id,
        public_note: record.publicNote,
        studio_id: record.resourceId,
        team_id: this.selectedSquad.id,
        players: recordsToIds(record.players),
        player_groups: recordsToIds(record.groups),
        player_workloads: record.playerWorkloads.map(rec => rec.workloadJSON),
        session_elements: record.sessionElements.map(rec => rec.toJSON()),
        status: record.status,
        start_at: record.startDate.toISOString(),
        end_at: record.endDate.toISOString(),
        color: record.eventColor,
        rpe: record.rpe,
        workload: record.workload,
        surface_id: record.surfaceId,
        is_snapshot: record.isSnapshot,
        snapshot_id: record.snapshotId,
      }

      return session
    },

    mapDataForRecord(data) {
      const sortNumbersAsc = (itemA, itemB) => {
        if (+itemA < +itemB) {
          return -1
        } else if (+itemA > +itemB) {
          return 1
        }

        return 0
      }

      const sortObjectsByIdAsc = (objA, objB) => {
        return sortNumbersAsc(objA.id, objB.id)
      }

      const rawEventTemplate = this.eventTemplates.find((template) => template.id === data.event_template_id)

      const rawEventType = rawEventTemplate
        ? this.eventTypes.find((type) => type.id === rawEventTemplate.event_type_id)
        : null

      const idsToRecords = (ids, source) => {
        if (!ids) {
          return []
        }

        return ids
          .sort(sortNumbersAsc)
          .map((itemId) => source.find((item) => item.id === itemId))
      }

      const playerWorkloadsToRecords = (playerIds = [], workloads = [], allPlayers = [], sessionElements = []) => {
        if (!playerIds) {
          return []
        }

        // Note, only athletes can have workload. Ignore staff.
        const athletes = allPlayers.filter((player) => playerIds.includes(player.id) && !player.is_staff)

        const playerWorkloads = athletes
          .sort(sortObjectsByIdAsc)
          .map((athlete) => {
            const id = athlete.id
            const athleteClone = ObjectHelper.clone(athlete)

            athleteClone.player_id = id
            delete athleteClone.id

            // Workload data
            const workload = workloads.find((workloadData) => workloadData.player_id === id) || {}

            // Elements associated with the player
            const rawPlayerSessionElements = sessionElements.filter(element => element.player_ids.includes(id))

            const rawPlayerElementWorkloads = (workload.player_element_workloads || []).sort(sortObjectsByIdAsc)

            // Create a record for every missing player element workload
            const populatedPlayerElementWorkloads = rawPlayerSessionElements.map(rawSessionElement => {
              // Find existing value in player element workloads
              const workloadElement = rawPlayerElementWorkloads.find(
                  rawElementWorkload => rawElementWorkload.element_id === rawSessionElement.element_id
              )

              if (workloadElement) {
                return workloadElement
              } else {
                console.log(`New player workload element is created for Element ID ${rawSessionElement.element_id}`)
                return ElementWorkloadModel.createRawElementWorkloadBasedOnRawSessionElement(rawSessionElement)
              }
            })

            return {
              ...athleteClone,
              ...workload,
              player_element_workloads: populatedPlayerElementWorkloads,
            }
          })

        return playerWorkloads
      }

      const sessionElements = (data.session_elements || []).sort(sortObjectsByIdAsc)

      const session = {
        id: data.id,
        name: data.title,
        sessionTemplateId: data.event_template_id,
        sessionTemplate: rawEventTemplate ? new SessionTemplateModel(rawEventTemplate) : null,
        sessionType: rawEventType ? new EventTypeModel(rawEventType) : null,
        resourceId: data.studio_id,
        duration: data.duration,
        durationUnit: data.duration_unit,
        players: idsToRecords(data.players, this.participants.allPlayers),
        playerWorkloads: playerWorkloadsToRecords(data.players, data.player_workloads, this.participants.allPlayers, sessionElements),
        groups: idsToRecords(data.player_groups, this.participants.playerGroups),
        sessionElements: sessionElements,
        status: data.status,
        publicNote: data.public_note,
        startDate: data.start_at,
        endDate: data.end_at,
        eventColor: data.color,
        rpe: data.rpe,
        // workload: data.workload, // Calc manually
        surfaceId: data.surface_id,
        isSnapshot: data.is_snapshot,
        readOnly: data.is_snapshot,
        snapshotId: data.snapshot_id,
      }

      return session
    },

    updateSelectedSquad(record) {
      this.selectedSquad = record
    },

    // region Group grid section
    groupGridMounted(grid) {
      this.groupGrid = grid
      this.initDragAndDropForGroupGrid()
    },

    groupGridUnmounted() {
      this.groupGridDragHelper.destroy()
      this.groupGridDragHelper = null
      this.groupGrid = null
    },

    initDragAndDropForGroupGrid() {
      if (this.scheduler && this.groupGrid && !this.groupGridDragHelper) {
        this.groupGridDragHelper = new GroupDrag({
          grid: this.groupGrid,
          schedule: this.scheduler,
          constrain: false,
          outerElement: this.groupGrid.element,
        })
      }
    },
    // endregion

    // region Player grid section
    playerGridMounted(grid) {
      this.playerGrid = grid
      this.initDragAndDropForPlayerGrid()
    },

    playerGridUnmounted() {
      this.playerGridDragHelper.destroy()
      this.playerGridDragHelper = null
      this.playerGrid = null
    },

    initDragAndDropForPlayerGrid() {
      if (this.scheduler && this.playerGrid && !this.playerGridDragHelper) {
        this.playerGridDragHelper = new PlayerDrag({
          grid: this.playerGrid,
          schedule: this.scheduler,
          constrain: false,
          outerElement: this.playerGrid.element,
        })
      }
    },
    // endregion

    // region Session template grid section
    sessionTemplateGridMounted(grid) {
      this.sessionTemplateGrid = grid
      this.initDragAndDropForSessionTemplateGrid()
    },

    sessionTemplateGridUnmounted() {
      this.sessionTemplateGridDragHelper.destroy()
      this.sessionTemplateGridDragHelper = null
      this.sessionTemplateGrid = null
    },

    initDragAndDropForSessionTemplateGrid() {
      if (this.scheduler && this.sessionTemplateGrid && !this.sessionTemplateGridDragHelper) {
        this.sessionTemplateGridDragHelper = new SessionTemplateDrag({
          grid: this.sessionTemplateGrid,
          schedule: this.scheduler,
          constrain: false,
          outerElement: this.sessionTemplateGrid.element,
        })
      }
    },
    // endregion

    initFitColumns() {
      if (this.scheduler.resourceColumns.fillWidth) {
        this.fitColumns = FIT_MENU_ITEM_FILL
      } else if (this.scheduler.resourceColumns.fitWidth) {
        this.fitColumns = FIT_MENU_ITEM_FIT
      } else {
        this.fitColumns = FIT_MENU_ITEM_NONE
      }
    },

    initColumnWidth() {
      this.columnWidth = this.scheduler.resourceColumns.columnWidth
    },

    initTickHeight() {
      this.tickHeight = this.scheduler.tickSize
    },

    updateDateRange({ startDate, endDate }) {
      this.updateSchedulerTimeSpan(startDate, endDate, this.viewPreset)
    },

    updateViewPreset(zoomLevelId) {
      // Need to consider for Week and Month views
      if (!this.scheduler) {
        return
      }
      const viewPresetRecord = this.scheduler.presets.getById(zoomLevelId)
      this.updateSchedulerTimeSpan(this.startDate, this.endDate, zoomLevelId)
      this.viewPreset = zoomLevelId
      this.updateColumnWidth(viewPresetRecord.tickWidth)
      this.updateTickHeight(viewPresetRecord.tickHeight)
    },

    updateSchedulerTimeSpan(startDate, endDate, zoomLevelId) {
      const viewPresetRecord = this.scheduler.presets.getById(zoomLevelId)
      const start = BenchmarkDateHelper.startOf(startDate, viewPresetRecord.shiftUnit, true, WEEK_START_DAY)
      const end = zoomLevelId === ZOOM_LEVEL_CUSTOM
        ? endDate
        : DateHelper.add(start, viewPresetRecord.shiftIncrement, viewPresetRecord.shiftUnit)

      this.scheduler.setTimeSpan(start, end)
      this.startDate = start
      this.endDate = end

      return { start, end }
    },

    /**
     * Automatically resize resource columns to fill available width.
     * `true` means columns will stretch (grow) to fill viewport.
     * `false` means columns will respect their configured columnWidth.
     * @param newValue boolean
     */
    updateFillWidth(newValue) {
      this.scheduler.resourceColumns.fillWidth = newValue
      this.initFitColumns()
    },

    /**
     * Automatically resize resource columns to always fit available width.
     * `true` means columns will grow or shrink to always fit viewport.
     * `false` means columns will respect their configured columnWidth.
     * @param newValue boolean
     */
    updateFitWidth(newValue) {
      this.scheduler.resourceColumns.fitWidth = newValue
      this.initFitColumns()
    },

    /**
     * Value is the column width (60px to 340px)
     * @param newValue number
     */
    updateColumnWidth(newValue) {
      this.scheduler.resourceColumns.columnWidth = newValue
      this.fitColumns = FIT_MENU_ITEM_NONE
      this.initColumnWidth()
    },

    /**
     * Value is the tick height (20px to 280px)
     * @param newValue number
     */
    updateTickHeight(newValue) {
      this.scheduler.suppressFit = true
      this.scheduler.tickSize = newValue
      this.initTickHeight()
    },

    updateSelectedTheme(newThemeId) {
      const oldThemeId = this.selectedTheme
      this.selectedTheme = setSelectedTheme(newThemeId, oldThemeId)
    },
  }
}
</script>
