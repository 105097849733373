import {
  ColumnConfig,
  GridConfig,
  GridFeaturesConfigType,
  StringHelper,
} from '@bryntum/schedulerpro'
import SessionStore from '@/components/bryntum/stores/SessionStore'
import SessionModel from '@/components/bryntum/models/SessionModel'

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5031
interface ColumnConfigExtension extends ColumnConfig {
  type: string
}

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5020
interface GridConfigExtension extends GridConfig {
  sortFeature: GridFeaturesConfigType['sort']
  headerMenuFeature: GridFeaturesConfigType['headerMenu']
}

const columns: Partial<ColumnConfigExtension>[] = [
  {
    field: 'name',
    text: 'Title',
    width: 240
  },
  {
    field: 'formattedStartDate',
    text: 'Start date',
    width: 100
  },
  {
    field: 'startTime',
    text: 'Start time',
    width: 100
  },
  {
    type: 'template',
    field: 'status',
    text: 'Status',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    template: ({ value = '' }) => StringHelper.xss`<div class="publish-status publish-status-${value}">${value}</div>`,
    width: 80
  },
]

const PublishSessionsGridConfig: Partial<GridConfigExtension> = {
  header: {
    title: 'Publish sessions',
    cls: 'text-h6',
  },
  bbar: {
    items: {
      filler: {
        type: 'widget',
        cls: 'b-toolbar-fill',
      },
      cancelButton: {
        type: 'button',
        text: 'Cancel',
        cls: 'b-raised b-gray',
        minWidth: 100,
        onClick: async ({ source: button }: any) => {
          const grid = button.up('grid')
          grid.trigger('cancel')
        },
      },
      publishButton: {
        type: 'button',
        text: 'Publish',
        icon: 'b-fa-lock',
        cls: 'b-raised b-blue',
        minWidth: 100,
        onClick: async ({ source: button }: any) => {
          const grid = button.up('grid')
          grid.trigger('publish')
        },
      },
    },
  },
  cls: 'publish-sessions-grid',
  readOnly: true,
  rowHeight: 40,
  selectionMode: {
    checkbox: true,
    showCheckAll: true,
  },
  // TODO: declare store type once the issue is fixed: https://github.com/bryntum/support/issues/5021
  store: {
    ...SessionStore.defaultConfig,
  },
  columns,
  // region Features
  sortFeature: false,
  headerMenuFeature: false,
  // endregion
}

export { PublishSessionsGridConfig }
